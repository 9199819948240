<template>
  <div class="circle-scale" :style="{height: scaleHeight+'px'}" ref="circleScale">
    <div class="circle-scale__area">
    </div>
    <img class="circle-scale__progress"
         src="@/assets/images/arrow-pointer.svg"
         ref="arrowPointer" alt="arrow-pointer">
  </div>
</template>

<script>
export default {
  name: 'CircleScale',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    scaleHeight: 0,
  }),
  methods: {
    onResize() {
      this.scaleHeight = Math.floor(this.$refs.circleScale.clientWidth / 2) ?? 0;
      this.$refs.arrowPointer.style.transform = `translateX(-12.5%) rotate(${(((200 / 100) * this.value) - 190)}deg)`;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.circle-scale {
  overflow: hidden;
  position: relative;
  &__area {
    position: relative;
    width: 100%;
    height: 200%;
    border-radius: 50%;
    background: linear-gradient(90deg, #FF7033 0%, rgba(255, 184, 0, 0.98) 29.69%, #F5EC00 63.02%, #ADFF00 100%);
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background: #FFFFFF;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__progress {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 20%;
    transform-origin: 12.5% 50%;
    transition: transform 1s;
    transform: translateX(-12.5%) rotate(-190deg);
  }
}
</style>
