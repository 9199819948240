<template>
  <div v-if="results && motivatorsAndDestructors && (motivators || destructors)">
    <div class="row mt-2 mt-md-8">
      <div v-if="motivators" class="col-md-5">
        <h3 class="page-title">Мотиваторы</h3>
        <div v-if="motivators.description" class="font-sm text-dark-gray mt-2">
          {{motivators.description}}
        </div>

        <div v-if="motivators.motivators">
          <div v-for="motivator in motivators.motivators" :key="motivator.title" class="mt-4 mt-md-6">
            <div class="page-subtitle">{{motivator.title}}:</div>
            <div class="page-description mt-1">{{motivator.description}}</div>

            <template v-if="motivator.recomendation">
              <div v-for="(recommendation, ind) in splitRecommendations(motivator.recomendation)" :key="ind"
                   class="recommend-item mt-3 font-sm text-dark-gray">
                {{recommendation}}
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="destructors" class="col-md-5 offset-md-2 mt-4 mt-md-0">
        <h3 class="page-title">Демотиваторы</h3>
        <div v-if="destructors.description" class="font-sm text-dark-gray mt-2">
          {{destructors.description}}
        </div>

        <div v-if="destructors.destructors">
          <div v-for="destructor in destructors.destructors" :key="destructor.title" class="mt-4 mt-md-6">
            <div class="page-subtitle">{{destructor.title}}:</div>
            <div class="page-description mt-1">{{destructor.description}}</div>

            <template v-if="destructor.recomendation">
              <div v-for="(recommendation, ind) in splitRecommendations(destructor.recomendation)" :key="ind"
                   class="recommend-item mt-3 font-sm text-dark-gray">
                {{recommendation}}
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MotivatorsAndDestructors',
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    motivatorsAndDestructors() {
      return this.results.motivators_and_destructors;
    },
    motivators() {
      return this.motivatorsAndDestructors.motivators;
    },
    destructors() {
      return this.motivatorsAndDestructors.destructors;
    },
  },
  methods: {
    splitRecommendations(recommendations) {
      const separator = '\n- ';
      return recommendations.split(separator).map((r, i) => (i === 0 ? r.substr(2) : r));
    },
  },
};
</script>

<style scoped lang="scss">
.recommend-item {
  position: relative;
  padding-left: 1.25rem;
  &::before {
    content: '—';
    position: absolute;
    left: 0;
  }
}
</style>
