<template>
  <div v-if="results && competences">
    <div class="d-flex align-center justify-space-between mt-4 mt-md-8">
      <h3 class="page-title">Личностные способности</h3>
    </div>
    <div class="compet-title-desc">Этот раздел посвящен описанию разнообразных особенностей личности, черт характера и степени склонности к ним</div>
    <div class="compet-titles">
      <div>Характеристика</div>
      <div>Степень предрасположенности</div>
    </div>
    <div class="compet-row" v-for="(item,i) in competences" :key="i">
      <div class="compet-row__1">
        <div class="compet-row__title">
          {{item.title}}
        </div>
        <div class="compet-row__blocks">
           <Scale10Blocks :value="item.value" />
        </div>
      </div>
      <div class="compet-row__desc">
        {{item.description}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scale10Blocks from '@/components/ui/Scale10ColorBlocks.vue';

export default {
  name: 'CompetenciesNew',
  components: {
    Scale10Blocks
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    competences() {
      this.results.competences.HIGHT.competences.forEach(el => {
        el.value = 8;
      });
      this.results.competences.MEDIUM.competences.forEach(el => {
        el.value = 5;
      });
      this.results.competences.LOW.competences.forEach(el => {
        el.value = 2;
      });
      return [...this.results.competences.HIGHT.competences, ...this.results.competences.MEDIUM.competences, ...this.results.competences.LOW.competences]
        .sort((a, b) => b.value - a.value);
    },
  },
  methods: {
  },
};
</script>

<style>
.compet-titles {
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    margin: 20px 0;
}

.compet-title-desc {
    margin: 20px 0;
}

.compet-row {
    border-top: 1px solid #ccc;
    padding: 15px 20px;
}

.compet-row__1 {
    display: flex;
}

.compet-row__title {
    flex: 50% 0 0;
    font-weight: bold;
    padding-top: 20px;
}

.compet-row__blocks {
    flex: 50% 0 0;
}

.compet-row__desc {
    margin-top: 10px;
}
</style>
